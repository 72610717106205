
@font-face {
    font-family: "Trade Gothic";
    src: url(../public/fonts/Trade_Gothic_Font/Trade\ Gothic\ LT.ttf);
    font-style: normal;
    
}

@font-face {
    font-family: "Gloss And Bloom";
    src: url(../public/fonts/Gloss_And_Bloom.ttf);
    font-style: normal;
    
}

/* @font-face {
    font-family: "Trade Gothic";
    src: url(../public/fonts/Trade_Gothic_Font/Trade\ Gothic\ LT\ Bold\ Condensed\ No.\ 20.ttf);

    font-size: bold;
    
} */

/* @font-face {
    font-family: "Trade Gothic";
    src: url(../public/fonts/Trade_Gothic_Font/Trade\ Gothic\ LT\ Bold\ Oblique.ttf);

    font-weight: bold;
    font-style: oblique;
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
  }

body{
    /* font-family: 'Trade Gothic';
    font-weight: normal; */
    font-family: 'Lato', sans-serif;
}

.title {
    /* font-family: 'Gloss And Bloom'; */
    font-family: 'Lato', sans-serif;
  }

.bg_itw{
    background-position: right top;
}